import {BindingList, createHtmlElementFromHtml, hasNoHidden} from "../lib/domFunctions";
import {Popover} from "bootstrap";
import {Messages} from "../messages/Messages";
import {ContainerActionEnvironmentFromPlayerData, ContainerActionRules} from "../lib/ContainerActionRules";
import {SendContainerToLoading} from "../modals/SendContainerToLoading";
import {SendContainerToWork} from "../modals/SendContainerToWork";
import {SendContainerToUnloading} from "../modals/SendContainerToUnloading";
import {FinishContainer} from "../modals/FinishContainer";
import {ContainerDataAdapter} from "./ContainerDataSupplier";
import {Program_State} from "../dto/com.rico.sb2.entity.program";
import {durationClock} from "../lib/timeFunctions";
import {ContainerState} from "../dto/com.rico.sb2.entity.detail";
import {SendContainerToPosition} from "../modals/SendContainerToPosition";
import {PopoverInstance} from "../lib/bootstrapPopover";
import {PlayerDataAdapter, PlayerDataListener, UpdateSchemeMessageConsumer} from "../PlayerDataAdapter";
import {UpdateContainerMessage, UpdateSchemeMessage, visitUpdateSchemeMessage} from "../dto/com.rico.sb2.message";
import {PlayerData_ContainerInfo} from "../dto/com.rico.sb2.service.positions";
import {PeriodicTask} from "../lib/PeriodicTask";
import {ContainerTypeNamer} from "../dto/com.rico.sb2.support";
import {AppBus, AppEvents} from "../AppBus";
import {ProcessControlService_Mode, TelemetryMessageProcessor_BathTelemetry} from "../dto/com.rico.sb2.service";
import {AppConfig} from "../AppConfig";
import {CancelContainer} from "../modals/CancelContainer";
import {CancelStarting} from "../modals/CancelStarting";
import {ChangeContainerStep} from "../modals/ChangeContainerStep";
import {LockContainer} from "../modals/LockContainer";
import {UnlockContainer} from "../modals/UnlockContainer";
import {DisbandContainer} from "../modals/DisbandContainer";
import {CloneContainer} from "../modals/CloneContainer";
import {SendLoadedToStorage} from "../modals/SendLoadedToStorage";


const messages = new Messages();
const containerTypeNamer = new ContainerTypeNamer();

export class ContainerClickMenu implements PopoverInstance, PlayerDataListener, UpdateSchemeMessageConsumer {
	private readonly element: HTMLElement
	private readonly bindings = new BindingList()
	private readonly data: ContainerDataAdapter
	private readonly playerData: PlayerDataAdapter
	private readonly tickTimer: PeriodicTask;

	constructor(playerData: PlayerDataAdapter, id: number) {
		this.playerData = playerData
		this.tickTimer = new PeriodicTask(this.tick.bind(this), {periodMs: 1000, enabled: false})

		this.data = playerData.getContainerData(id)

		let showToStorageButton = '';
		if (AppConfig.showSendToStorage) {
			showToStorageButton = `<button type="button" class="text-nowrap btn btn-secondary btn-sm mt-2 me-1" data-bind="doSendToStorage">${messages.get('ContainerListItem.clickMenu.doSendToStorage')}</button>`;
		}

		this.element = createHtmlElementFromHtml(`
<div>
    <div class="mt-3">
        <table class="w-100 table table-borderless mb-0">
            <tr><td class="text-nowrap pe-3"><span class="text-nowrap" data-bind="type"></span> №<span data-bind="number"></span></td><td class="text-end text-lowercase"><span data-bind="state"></span></td></tr>
            <tr data-bind="processInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.process')}</td><td class="text-end"><span data-bind="process"></span></td></tr>
            <tr data-bind="coatingInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.coating')}</td><td class="text-end"><span data-bind="coating"></span></td></tr>
            <tr data-bind="durationInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.duration')}</td><td class="text-end"><span data-bind="duration"></span></td></tr>
            <tr data-bind="areaInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.area')}</td><td class="text-end"><span data-bind="area"></span></td></tr>
        </table>
    </div>
    <hr class="my-2" data-bind="hr2">
    <div>
        <table class="w-100 table table-borderless mb-0">
            <tr data-bind="operatorInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.operator')}</td><td class="text-end"><span data-bind="operator"></span></td></tr>
            <tr data-bind="createdInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.created')}</td><td class="text-end"><span data-bind="created"></span></td></tr>
            <tr data-bind="programStartInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.programStart')}</td><td class="text-end"><span data-bind="programStart"></span></td></tr>
            <tr data-bind="programFinishInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.programFinish')}</td><td class="text-end"><span data-bind="programFinish"></span></td></tr>
        </table>
    </div>
    <hr class="my-2" data-bind="hr5">
    <div>
        <table class="w-100 table table-borderless mb-0">
            <tr class="align-baseline" data-bind="stepDoneInfo">
                <td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.stepDone')}</td>
                <td class="text-nowrap text-end"><span data-bind="stepDone"></span> <button class="ms-2 btn btn-sm btn-success hidden" data-bind="doChangeStep">Поменять шаг</button></td>
            </tr>
            <tr class="align-baseline" data-bind="positionNowInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.positionNow')}</td><td class="text-end"><span data-bind="positionNow"></span></td></tr>
            <tr class="align-baseline" data-bind="positionNextInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.positionNext')}</td><td class="text-end"><span data-bind="positionNext"></span></td></tr>
            <tr class="align-baseline" data-bind="positionEndInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.positionEnd')}</td><td class="text-end"><span data-bind="positionEnd"></span></td></tr>
        </table>
    </div>
    <hr class="my-2" data-bind="hr4">
    <div>
        <table class="w-100 table table-borderless mb-0">
            <tr data-bind="currentInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.current')}</td><td class="text-end text-nowrap"><span data-bind="current"></span> А</td></tr>
            <tr data-bind="voltageInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.voltage')}</td><td class="text-end text-nowrap"><span data-bind="voltage"></span> V</td></tr>
            <tr data-bind="tempInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.temp')}</td><td class="text-end text-nowrap"><span data-bind="temp"></span> ℃</td></tr>
        </table>
    </div>
    <hr class="my-2" data-bind="hr3">
    <div>
        <table class="w-100 table table-borderless mb-0">
            <tr data-bind="startInInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.startIn')}</td><td class="text-end"><span data-bind="startIn"></span></td></tr>
            <tr data-bind="finishInInfo"><td class="text-nowrap pe-3">${messages.get('ContainerListItem.clickMenu.finishIn')}</td><td class="text-end"><span data-bind="finishIn"></span></td></tr>
            <tr data-bind="overexposure"><td class="text-nowrap pe-3"></td><td class="text-end text-danger">${messages.get('ContainerListItem.overexposureText')}</td></tr>
        </table>
    </div>
    <div class="text-center px-3 my-3">
        <div class="text-nowrap me-n1">
            <button type="button" class="text-nowrap btn btn-secondary btn-sm mt-2 me-1"  data-bind="doView">${messages.get('ContainerListItem.clickMenu.doView')}</button>
<!--            <button type="button" class="text-nowrap btn btn-secondary btn-sm mt-2 me-1" data-bind="doEdit">${messages.get('ContainerListItem.contextMenu.doEdit')}</button>-->
            <button type="button" class="text-nowrap btn btn-secondary btn-sm mt-2 me-1" data-bind="doPrint">${messages.get('ContainerListItem.contextMenu.doPrint')}</button>
            <button type="button" class="text-nowrap btn btn-secondary btn-sm mt-2 me-1" data-bind="doClone">${messages.get('ContainerListItem.contextMenu.doClone')}</button>
        </div>
        
        <div class="me-n1" role="group" data-bind="buttons2">
            <button type="button" class="text-nowrap btn btn-warning btn-sm mt-2 me-1" data-bind="doLock">${messages.get('ContainerListItem.contextMenu.doLock')}</button>
            <button type="button" class="text-nowrap btn btn-warning btn-sm mt-2 me-1" data-bind="doUnlock">${messages.get('ContainerListItem.contextMenu.doUnlock')}</button>
            <button type="button" class="text-nowrap btn btn-secondary btn-sm mt-2 me-1" data-bind="doMove">${messages.get('ContainerListItem.contextMenu.doMove')}</button>
            <button type="button" class="text-nowrap btn btn-success btn-sm mt-2 me-1" data-bind="doLoad">${messages.get('ContainerListItem.clickMenu.doLoad')}</button>
            <button type="button" class="text-nowrap btn btn-success btn-sm mt-2 me-1" data-bind="doStart">${messages.get('ContainerListItem.clickMenu.doStart')}</button>
            ${showToStorageButton}
            <button type="button" class="text-nowrap btn btn-success btn-sm mt-2 me-1" data-bind="doUnload">${messages.get('ContainerListItem.clickMenu.doUnload')}</button>
            <button type="button" class="text-nowrap btn btn-success btn-sm mt-2 me-1" data-bind="doFinish">${messages.get('ContainerListItem.clickMenu.doFinish')}</button>
            <button type="button" class="text-nowrap btn btn-success btn-sm mt-2 me-1" data-bind="doDisband">${messages.get('ContainerListItem.clickMenu.doDisband')}</button>
            <button type="button" class="text-nowrap btn btn-danger btn-sm mt-2 me-1" data-bind="doCancel">${messages.get('ContainerListItem.contextMenu.doCancel')}</button>
            <button type="button" class="text-nowrap btn btn-danger btn-sm mt-2 me-1" data-bind="doCancelPlanned">${messages.get('ContainerListItem.contextMenu.doDelete')}</button>
            <button type="button" class="text-nowrap btn btn-danger btn-sm mt-2 me-1" data-bind="doCancelStarting">${messages.get('ContainerListItem.contextMenu.doCancelStarting')}</button>
        </div>
    </div>
</div>
`)

		this.bindings.collect(this.element)
		this.bindings.update('doLoad', node => node.addEventListener('click', this.doLoad.bind(this)));
		this.bindings.update('doStart', node => node.addEventListener('click', this.doStart.bind(this)));
		this.bindings.update('doSendToStorage', node => node.addEventListener('click', this.doSendToStorage.bind(this)));
		this.bindings.update('doUnload', node => node.addEventListener('click', this.doUnload.bind(this)));
		this.bindings.update('doFinish', node => node.addEventListener('click', this.doFinish.bind(this)));
		this.bindings.update('doDisband', node => node.addEventListener('click', this.doDisband.bind(this)));
		this.bindings.update('doMove', node => node.addEventListener('click', this.doMove.bind(this)));
		this.bindings.update('doView', node => node.addEventListener('click', this.doView.bind(this)));
		this.bindings.update('doEdit', node => node.addEventListener('click', this.doEdit.bind(this)));
		this.bindings.update('doCancel', node => node.addEventListener('click', this.doCancel.bind(this)));
		this.bindings.update('doCancelPlanned', node => node.addEventListener('click', this.doCancelPlanned.bind(this)));
		this.bindings.update('doCancelStarting', node => node.addEventListener('click', this.doCancelStarting.bind(this)));
		this.bindings.update('doPrint', node => node.addEventListener('click', this.doPrint.bind(this)));
		this.bindings.update('doClone', node => node.addEventListener('click', this.doClone.bind(this)));
		this.bindings.update('doChangeStep', node => node.addEventListener('click', this.doChangeStep.bind(this)));
		this.bindings.update('doLock', node => node.addEventListener('click', this.doLock.bind(this)));
		this.bindings.update('doUnlock', node => node.addEventListener('click', this.doUnlock.bind(this)));
		this.update();
	}

	createPopover(onElement: Element, popoverContainer: Element = document.body) {
		this.playerData.updateListeners.add(this)
		this.playerData.playerDataListeners.add(this)

		return new Popover(onElement, {
			animation: false, sanitize: false, trigger: 'manual', placement: 'top', html: true, content: this.element, container: popoverContainer,
			template: `<div class="popover dashboard-popover" role="popover"><div class="popover-arrow"></div><div class="popover-body p-0"></div></div>`
		});
	}

	hidePopover(): void {
		this.playerData.updateListeners.remove(this)
		this.playerData.playerDataListeners.remove(this)
		this.tickTimer.enabled = false
	}

	onSchemeUpdate(update: UpdateSchemeMessage): void {
		visitUpdateSchemeMessage(update, {
			containerMessage: m => this.onSchemeContainerUpdate(m)
		})
	}

	private onSchemeContainerUpdate(update: UpdateContainerMessage): void {
		if (update.id == this.data.id) {
			this.update();
		}
	}

	onSchemePositionTelemetry(update: TelemetryMessageProcessor_BathTelemetry): void {
		if (update.id == this.data.position) {
			this.update();
		}
	}

	onContainerModify(m: PlayerData_ContainerInfo): void {
		if (m.id == this.data.id) {
			this.update();
		}
	}

	update() {
		const data = this.data

		this.bindings.update('type', node => node.innerText = containerTypeNamer.name(data.type))
		this.bindings.update('number', node => node.innerText = data.number);
		this.bindings.update('state', node => node.innerText = data.stateString(messages));
		this.bindings.update('process', node => node.innerText = data.programProcess);
		this.bindings.toggle('processInfo', data.programProcess.length > 0);
		this.bindings.update('coating', node => node.innerText = data.programCoating);
		this.bindings.toggle('coatingInfo', data.programCoating.length > 0);
		this.bindings.update('area', node => node.innerText = messages.get(`ContainerListItem.clickMenu.areaValue`, data.areaString));
		this.bindings.toggle('areaInfo', data.areaString.length > 0);

		if (data.programState === Program_State.NEW) {
			this.bindings.update('duration', node => node.innerText = data.estimateDuration);
			this.bindings.toggle('durationInfo', data.estimateDuration.length > 0);
		} else {
			this.bindings.update('duration', node => node.innerText = data.duration);
			this.bindings.toggle('durationInfo', data.duration.length > 0);
		}

		this.bindings.update('operator', node => node.innerText = data.owner);
		this.bindings.toggle('operatorInfo', data.owner.length > 0);
		this.bindings.update('created', node => node.innerText = data.createDate);
		this.bindings.toggle('createdInfo', data.createDate.length > 0);
		this.bindings.update('programStart', node => node.innerText = data.programStartString);
		this.bindings.toggle('programStartInfo', data.programStartString.length > 0);
		this.bindings.update('programFinish', node => node.innerText = data.programFinishString);
		this.bindings.toggle('programFinishInfo', data.programFinishString.length > 0);
		this.bindings.toggle('hr2', this.bindings.someAny(['operatorInfo', 'createdInfo', 'programStartInfo', 'programFinishInfo'], hasNoHidden))

		this.bindings.update('startIn', node => node.innerText = data.programPlannedIn);
		this.bindings.toggle('startInInfo', data.programPlannedIn.length > 0);

		const finishIn = data.finishInDuration
		const finishInString = finishIn == null ? '' : durationClock(finishIn);
		this.bindings.update('finishIn', node => node.innerText = finishInString)
		this.bindings.toggle('finishInInfo', finishInString.length > 0)
		this.bindings.toggle('overexposure', data.overexposure)
		this.bindings.toggle('hr3', this.bindings.someAny(['startInInfo', 'finishInInfo', 'overexposure'], hasNoHidden))

		this.bindings.update('current', node => node.innerText = data.currentString);
		this.bindings.toggle('currentInfo', data.currentString.length > 0);
		this.bindings.update('voltage', node => node.innerText = data.voltageString);
		this.bindings.toggle('voltageInfo', data.voltageString.length > 0);
		this.bindings.update('temp', node => node.innerText = data.temperatureString);
		this.bindings.toggle('tempInfo', data.temperatureString.length > 0);
		this.bindings.toggle('hr4', this.bindings.someAny(['currentInfo', 'voltageInfo', 'tempInfo'], hasNoHidden))

		this.bindings.update('stepDone', node => node.innerText = data.stepDoneString);
		this.bindings.toggle('stepDoneInfo', data.stepDoneString.length > 0);

		const canChangeStep = data.serviceMode != null
			&& data.stepDonePair != null
			&& new Set([ProcessControlService_Mode.SEMIAUTOMATIC, ProcessControlService_Mode.MANUAL, ProcessControlService_Mode.PAUSED]).has(data.serviceMode);
		this.bindings.toggle('doChangeStep', canChangeStep);

		this.bindings.update('positionNow', node => node.innerText = data.positionNowString);
		this.bindings.toggle('positionNowInfo', data.positionNowString.length > 0);
		this.bindings.update('positionNext', node => node.innerText = data.positionNextString);
		this.bindings.toggle('positionNextInfo', data.positionNextString != data.positionNowString && data.positionNextString.length > 0);
		this.bindings.update('positionEnd', node => node.innerText = data.positionEndString);
		this.bindings.toggle('positionEndInfo', data.positionEndString != data.positionNowString && data.positionEndString.length > 0);
		this.bindings.toggle('hr5', this.bindings.someAny(['stepDoneInfo', 'positionNowInfo', 'positionNextInfo', 'positionEndInfo'], hasNoHidden))

		const rules = new ContainerActionRules(new ContainerActionEnvironmentFromPlayerData(this.playerData, data.serviceMode), data.state, data.position)

		this.bindings.toggle('doView', data.state != ContainerState.EMPTY)
		this.bindings.toggle('doEdit', rules.editable)
		this.bindings.toggle('doPrint', data.state != ContainerState.EMPTY && AppConfig.logged())
		this.bindings.toggle('doClone', data.state != ContainerState.EMPTY && AppConfig.logged())
		this.bindings.toggle('doMove', rules.canMove)

		const canCancelPlanned = this.data.state == ContainerState.PLANNED;
		this.bindings.toggle('doCancelPlanned', canCancelPlanned);
		this.bindings.toggle('doCancel', !canCancelPlanned && rules.cancelable);
		this.bindings.toggle('doCancelStarting', this.data.cancelStartingAvailable);

		this.bindings.toggle('doLoad', rules.canToLoad)
		this.bindings.toggle('doStart', rules.canToWork)
		this.bindings.toggle('doUnload', rules.canToUnload)

		const sendToStorageEnabled = data.state == ContainerState.LOADING
			&& data.atLoadingPosition
			&& rules.canToWork
		this.bindings.toggle('doSendToStorage', sendToStorageEnabled)

		const lockUnlockEnabled = data.serviceMode != null
			&& new Set([ProcessControlService_Mode.SEMIAUTOMATIC, ProcessControlService_Mode.PAUSED, ProcessControlService_Mode.MANUAL]).has(data.serviceMode);
		this.bindings.toggle('doLock', lockUnlockEnabled && data.state == ContainerState.PROCESSING);
		this.bindings.toggle('doUnlock', lockUnlockEnabled && data.state == ContainerState.BLOCKED);

		this.bindings.toggle('doFinish', rules.canFinish)
		this.bindings.toggle('doDisband', rules.canDisband)

		this.tickTimer.enabled = data.programFinishTime != null;

		this.element.querySelectorAll("table").forEach(function (table) {
			table.querySelectorAll("tbody > tr:not(.hidden)").forEach(function (node, index) {
				node.classList.toggle("stripe", (index % 2) == 0);
			});
		})

		return this;
	}

	doLoad() {
		new SendContainerToLoading(this.data.id).showModal()
	}

	doUnload() {
		new SendContainerToUnloading(this.data.id).showModal()
	}

	doFinish() {
		new FinishContainer(this.data.id).showModal()
	}

	doDisband() {
		new DisbandContainer(this.data.id).showModal()
	}

	doClone() {
		new CloneContainer(this.data.id).showModal()
	}

	doMove() {
		new SendContainerToPosition(this.data.id, this.data.position).showModal()
	}

	doView() {
		AppBus.fire(AppEvents.VIEW_CONTAINER, this.data.id, true);
		// createAnchorAndClick(`${AppConfig.CP}/queue/${this.data.id}`, true)
	}

	doEdit() {
		AppBus.fire(AppEvents.EDIT_CONTAINER, this.data.id);
		// createAnchorAndClick(`${AppConfig.CP}/queue/${this.data.id}/edit`, true)
	}

	doPrint() {
		AppBus.fire(AppEvents.PRINT_CONTAINER, this.data.id);
	}

	doCancel() {
		const state = this.data.state;
		if (state == null) return;
		new CancelContainer(this.data.id, state).showModal();
	}

	doCancelPlanned() {
		const state = this.data.state;
		if (state == null) return;
		new CancelContainer(this.data.id, state, {
			titleCode: 'CancelContainer.deleteTitle',
			buttonTextCode: 'button.delete',
			buttonOkClass: 'btn-danger'
		}).showModal();
	}

	doCancelStarting() {
		new CancelStarting(this.data.id).showModal();
	}

	doStart() {
		new SendContainerToWork(this.data.id).showModal()
	}

	doSendToStorage() {
		new SendLoadedToStorage(this.data.id).showModal()
	}

	doChangeStep() {
		new ChangeContainerStep(this.data.id).showModal();
	}

	doLock() {
		new LockContainer(this.data.id).showModal();
	}

	doUnlock() {
		new UnlockContainer(this.data.id).showModal();
	}

	tick() {
		this.update();
	}
}
