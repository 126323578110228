import {escapeHTML} from "./lib/escapeHTML";
import {springNumber, springSelect} from "./lib/springForms";
import {Messages} from "./messages/Messages";
import {createHtmlElement, querySelectorWithName, toggleHidden} from "./lib/domFunctions";

const messages = new Messages();

function removeFadeOut<T extends HTMLElement>(el: T, speed: number): Promise<T> {
    const seconds = speed / 1000;
    el.style.transition = "opacity " + seconds + "s ease";
    el.style.opacity = '0';
    return new Promise((resolve) => {
        setTimeout(function () {
            resolve(el)
            el.style.transition = "";
            el.style.opacity = '';
        }, speed);
    });
}

function toggleAttribute(el: Element | null, name: string, set: boolean) {
    if (el == null) return;
    if (set) el.setAttribute(name, `${set}`);
    else el.removeAttribute(name);
}

const HAS_RECTIFIER_PROFILE = 'has-rectifier-profile';

interface ProcessEditPageParams {
    positionTypes: any
    rectifierProfiles: any
    suspensionTakeDelayFactor: number
}

export class ProcessEditPage {
    private readonly positionTypes: { id: number, name: string, hasMaxCurrent: boolean, selectable: boolean }[];
    private readonly rectifierProfiles: { id: number, name: string, type: string }[];
    private readonly suspensionTakeDelayFactor: number
    private readonly stepsTable: HTMLTableSectionElement;
    private actionCount: number;

    constructor({positionTypes, rectifierProfiles, suspensionTakeDelayFactor}: ProcessEditPageParams) {
        this.stepsTable = document.getElementById('steps')!.querySelector('tbody')!!
        this.actionCount = this.stepsTable.querySelectorAll('tr').length;
        this.positionTypes = positionTypes;
        this.rectifierProfiles = rectifierProfiles;
        this.suspensionTakeDelayFactor = suspensionTakeDelayFactor

        this.stepsTable.addEventListener('change', e => {
            if (!e.target) return;

            const target = e.target as HTMLInputElement
            if (/\.positionType$|\.rectifierProfile$|\.minDuration$/.test(target.name)) {
                this.updateRowState(target.closest('tr'))
            }
        });

        this.stepsTable.addEventListener('input', e => {
            if (!e.target) return;

            const target = e.target as HTMLInputElement
            if (/\.minDuration$/.test(target.name)) {
                this.updateRowState(target.closest('tr'))
            }
        });
    }

    private updateRowState(tr: HTMLTableRowElement | null) {
        if (!tr) return
        this.updateRowShowMaxCurrentFields(tr)
        this.updateRowShowDynamicNotice(tr)
    }

    private updateRowShowMaxCurrentFields(tr: HTMLTableRowElement) {
        function toggle(show: boolean) {
            toggleAttribute(tr, HAS_RECTIFIER_PROFILE, show);
            tr.querySelectorAll<HTMLElement>('[data-visible-for-max-current]').forEach(node => node.classList.toggle('hidden', !hasMaxCurrent));
        }

        const positionTypeSelect = querySelectorWithName<HTMLSelectElement>(tr, 'select', /\.positionType$/)
        if (!positionTypeSelect) return toggle(false);

        const positionType = Number(positionTypeSelect.value);
        const hasMaxCurrent = this.positionTypes.filter(t => t.id === positionType).map(t => t.hasMaxCurrent)[0] || false;

        toggle(hasMaxCurrent)
    }

    private updateRowShowDynamicNotice(tr: HTMLTableRowElement) {
        function toggle(show: boolean) {
            tr.classList.toggle('dynamic-step-row', show)
            tr.querySelectorAll<HTMLElement>('[data-bind="dynamicNotice"]').forEach(node => toggleHidden(node, !show))
        }

        if (!tr.hasAttribute(HAS_RECTIFIER_PROFILE)) return toggle(false);

        const rectifierProfileSelect = querySelectorWithName<HTMLSelectElement>(tr, 'select', /\.rectifierProfile$/)
        if (!rectifierProfileSelect) return toggle(false);

        const rectifierProfile = Number(rectifierProfileSelect.value);
        const rectifierProfileTypeCurrent = this.rectifierProfiles.filter(t => t.id === rectifierProfile).map(t => t.type == 'CURRENT')[0] || false;

        const minDurationText = querySelectorWithName<HTMLInputElement>(tr, 'input', /\.minDuration$/)?.value
        const minDurationValue = parseFloat(minDurationText == undefined ? '' : minDurationText)

        toggle(rectifierProfileTypeCurrent && !Number.isFinite(minDurationValue))
    }

    addStep() {
        const actionIndex = this.actionCount++;

        const positionTypeOptions = this.positionTypes
            .filter(item => item.selectable)
            .map(item => `<option value="${item.id}">${escapeHTML(item.name)}</option>`)
            .join('');
        const positionTypeSelect = springSelect({name: `actions[${actionIndex}].positionType`, labelCode: 'processAction.form.positionType', optionContent: positionTypeOptions, required: true});

        const rectifierProfileOptions = `<option value=""></option>` +
            this.rectifierProfiles
                .map(item => `<option value="${item.id}">${escapeHTML(item.name)}</option>`)
                .join('');
        const rectifierProfileSelect = springSelect({name: `actions[${actionIndex}].rectifierProfile`, labelCode: 'processAction.form.rectifierProfile', optionContent: rectifierProfileOptions});

        const runoffTimeOptions = Array.from(new Array(10).keys())
            .map(i => i * this.suspensionTakeDelayFactor)
            .map(n => `<option value="${n}">${n}</option>`)
            .join('');
        const runoffTimeSelect = springSelect({name: `actions[${actionIndex}].runoffTime`, labelCode: 'processAction.form.runoffTime', optionContent: runoffTimeOptions});

        const tr = createHtmlElement('tr', {class: 'align-baseline'}, `
                            <td class="border-5 border-bottom border-top border-start px-3 pb-0">
                                <input type="hidden" name="actions[${actionIndex}].id" value="0">
                                <input type="hidden" name="actions[${actionIndex}].order" data-bind="order">
                                <strong class="d-block text-center text-nowrap mb-2">
                                    ${messages.get('processAction.form.order')}
                                    <span data-bind="order"></span>
                                </strong>
                                <div class="text-center text-nowrap">
                                    <button type="button" class="btn btn-danger text-capitalize me-3" onclick="controller.removeStep(this)" title="${messages.get('process.form.stepRemove')}">
                                        <i class="fal fa-times"></i></button>
                                    <button type="button" class="btn btn-secondary text-capitalize muted-in-last-row" onclick="controller.stepOrderPlus(this)" title="${messages.get('process.form.stepDown')}">
                                        <i class="fal fa-arrow-down"></i></button>
                                    <button type="button" class="btn btn-secondary text-capitalize muted-in-first-row" onclick="controller.stepOrderMinus(this)" title="${messages.get('process.form.stepUp')}">
                                        <i class="fal fa-arrow-up"></i></button>
                                </div>
                                
                                <div class="mt-2 text-lowercase text-center cursor-default hidden" data-bind="dynamicNotice" title="${messages.get('processAction.form.dynamicNotice')}">
                                    <small class="badge text-bg-info">${messages.get('processAction.form.dynamicNoticeShort')}</small>
                                </div>
                            </td>
                            <td class="border-5 border-bottom border-top border-end pb-0">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-3 form-label-strong">${positionTypeSelect}</div>
                                        <div class="mb-3" data-visible-for-max-current>${rectifierProfileSelect}</div>
                                    </div>

                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-3">${springNumber({name: `actions[${actionIndex}].minDuration`, labelCode: 'processAction.form.minDuration', step: 1})}</div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-3">${springNumber({name: `actions[${actionIndex}].maxDuration`, labelCode: 'processAction.form.maxDuration', step: 1})}</div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-3 ">${runoffTimeSelect}</div>
                                            </div>
                                        </div>
                                        <div class="row" data-visible-for-max-current>
                                            <div class="col-md-4">
                                                <div class="mb-3">${springNumber({name: `actions[${actionIndex}].minCurrentDensity`, labelCode: 'processAction.form.minCurrentDensity', step: 'any'})}</div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-3">${springNumber({name: `actions[${actionIndex}].maxCurrentDensity`, labelCode: 'processAction.form.maxCurrentDensity', step: 'any'})}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
         `)

        this.updateRowShowMaxCurrentFields(tr);
        this.updateRowShowDynamicNotice(tr);
        this.stepsTable.append(tr);
        this.recalculateOrders();

        tr.scrollIntoView(true);
    }

    private recalculateOrders() {
        this.stepsTable.querySelectorAll('tr').forEach((tr, index) => {
            const orderString = (index + 1).toString();
            tr.querySelectorAll<HTMLInputElement>('input[data-bind="order"]').forEach(input => input.value = orderString)
            tr.querySelectorAll<HTMLElement>('span[data-bind="order"]').forEach(node => node.innerText = orderString)
        });
    }

    removeStep(button: HTMLButtonElement) {
        removeFadeOut(button.closest('tr')!!, 300).then(tr => {
            tr.remove();
            this.recalculateOrders();
        });
    }

    stepOrderMinus(button: HTMLButtonElement) {
        const tr = button.closest('tr')!!
        const previous = tr.previousElementSibling
        if (previous) {
            swapWithNext(previous).then(() => this.recalculateOrders());
        }
    }

    stepOrderPlus(button: HTMLButtonElement) {
        const tr = button.closest('tr')!!
        const next = tr.nextElementSibling
        if (next) {
            swapWithNext(tr).then(() => this.recalculateOrders());
        }
    }
}

function swapWithNext(el: Element): Promise<boolean> {
    const next = el.nextElementSibling;
    if (!next) return Promise.reject('no next sibling');

    let rowHeight = el.clientHeight;
    let animationLength = 300;

    el.setAttribute('style', `transform: translate(0px, ${rowHeight}px); background-color:  cadetblue; transition: transform ${animationLength}ms`);
    next.setAttribute('style', `transform: translate(0px, -${rowHeight}px); background-color: aliceblue; transition: transform ${animationLength}ms`);

    return new Promise((resolve) => {
        setTimeout(function () {
            next.after(el);
            next.removeAttribute('style');
            el.removeAttribute('style');

            resolve(true);
        }, animationLength);
    })
}