import {ContainerTypeInfo} from "./dto/com.rico.sb2.service.queue";
import {UserPermissions} from "./UserPermissions";

export const AppConfig = {
	CP: "",
	locale: "",

	roles: [],
	userPermissions: {} as UserPermissions,

	ContainerTypes: [] as ContainerTypeInfo[],
	ContainerTypeNamerConfig: {
		defaultName: '',
		names: {}
	},

	hideDashboardForm: {
		minWindowWidth: null,
		minWindowHeight: null,
	},

	showSendToStorage: false,

	trolleyLine: false,

	logged() {
		return this.roles.length > 0
	}
}