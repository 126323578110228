import {escapeHTML} from "./escapeHTML";
import {Modal} from "bootstrap";
import {Messages} from "../messages/Messages";
import {createHtmlElement} from "./domFunctions";
import {buttonEnable, buttonProgress} from "./buttonProgress";

const messages = new Messages();

export interface BootstrapModalOptions {
    title: string
    body: string
    footer?: string

    buttonOk?: string | null
    buttonOkClass?: string
    buttonCancel?: string | null

    closeable?: boolean
    modalClass?: string
    contentClass?: string
}

const BootstrapModalOptionsDefaults: BootstrapModalOptions = {
    title: '',
    body: '',

    buttonOk: messages.get('button.save'),
    buttonOkClass: 'btn-primary',
    buttonCancel: messages.get('button.cancel'),

    closeable: true,
    modalClass: '',
    contentClass: ''
}

interface BootstrapModalResult {
    content: HTMLElement,
    modal: Modal,
    buttonOk: HTMLButtonElement | null,
    buttonOkClickStart: () => void,
    buttonOkClickFinish: () => void,
    buttonCancel: HTMLButtonElement | null
}

export function bootstrapModal(options: BootstrapModalOptions): BootstrapModalResult {
    options = Object.assign({}, BootstrapModalOptionsDefaults, options);

    const close = options.closeable ? `<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>` : ''

    let footer = '';
    if (options.footer !== null && options.footer !== undefined) {
        footer = options.footer
    } else {
        if (options.buttonOk) {
            footer += `<button type="button" class="btn ${options.buttonOkClass}  text-capitalize" data-handler="submit">${escapeHTML(options.buttonOk)}</button>`;
        }
        if (options.buttonCancel) {
            footer += `<button type="button" class="btn btn-secondary text-capitalize" data-bs-dismiss="modal">${escapeHTML(options.buttonCancel)}</button>`;
        }
    }

    if (footer.length > 0) {
        footer = `<div class="modal-footer">${footer}</div>`;
    }

    let body = options.body ? `<div class="modal-body">${options.body}</div>` : '';

    const content = createHtmlElement('div', {class: `modal ${options.modalClass}`, 'tab-index': '-1'}, `
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content ${options.contentClass}">
      <div class="modal-header">
        <h5 class="modal-title">${escapeHTML(options.title)}</h5>
        ${close}
      </div>
      ${body}
      ${footer}
    </div>
  </div>
`)

    content.addEventListener('hidden.bs.modal', () => {
        content.remove();
    });

    const buttonOk = content.querySelector<HTMLButtonElement>('button[data-handler="submit"]') || null;
    const buttonCancel = content.querySelector<HTMLButtonElement>('.modal-footer button[data-bs-dismiss="modal"]') || null;
    let buttonOkProgress: { stop: () => void } | null = null;

    return {
        content,
        modal: new Modal(content, {
            keyboard: options.closeable,
            backdrop: options.closeable || 'static'
        }),
        buttonOk,
        buttonCancel,

        buttonOkClickStart: () => {
            if (buttonOk) {
                buttonOkProgress = buttonProgress(buttonOk);
            }
            buttonEnable(buttonCancel, false);
        },
        buttonOkClickFinish: () => {
            buttonOkProgress?.stop()
            buttonOkProgress = null;
            buttonEnable(buttonCancel, true);
        }
    };
}