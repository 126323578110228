import {jQuery} from './boot'
import 'bootstrap-table';
import {AppConfig} from "./AppConfig";
import just from "./lib/just";
import {escapeHTML} from "./lib/escapeHTML";
import {DetailListItem} from "./dto/com.rico.sb2.service.details";
import {coalesceEmpty} from "./lib/coalesce";

const h = escapeHTML;

export class DetailListPage {
    private readonly canEdit: boolean;

    constructor(canEdit: boolean) {
        this.canEdit = canEdit;

        const table = jQuery('#table');
        table.bootstrapTable({
            url: `${AppConfig.CP}/details/listPage`,
            sidePagination: 'server',
            rowStyle: () => just({classes: 'align-center'}),
            onClickRow: (row) => {
                if (this.canEdit) {
                    document.location.href = `${AppConfig.CP}/details/${row.id}/edit`
                }
            },
            responseHandler: (res) => just({total: res.totalElements, rows: res.content}),
            onPostBody: () => this.alignProcessCoatingCells(table),
            columns: [
                {},
                {},
                {},
                {},
                {class: 'w-5em text-center'},
                {class: 'w-5em text-center'},
                {class: 'w-5em text-center'},
                {class: 'w-5em text-center'},
                {class: 'w-5em text-center'},
                {class: 'w-5em text-center'},
                {
                    class: 'text-center p-0',
                    formatter: (value: any, row: DetailListItem, index: any) => {
                        const table = buildProcessAndCoatings(row);
                        if (!table) return '';

                        const tableHtml = table
                            .map((r, pi) => `<tr class="last-border-bottom-0 first-border-top-0"><td class="w-50 text-end px-2 py-2 border-start border-end" data-tp="${index}_${pi}"><span title="${h(r.name)}">${h(r.code) || '&nbsp;'}</span></td></tr>`)
                            .join("")
                        return `<table class="w-100 table m-0">${tableHtml}</table>`
                    }
                },
                {
                    class: 'text-center p-0',
                    formatter: (value: any, row: DetailListItem, index: any) => {
                        const table = buildProcessAndCoatings(row);
                        if (!table) return '';

                        const tableHtml = table
                            .map((r, pi) => `<tr class="last-border-bottom-0 first-border-top-0"><td class="text-start w-50 px-2  border-end" data-tp-height="${index}_${pi}">${h(r.coatings.join(", "))}</td></tr>`)
                            .join("")
                        return `<table class="w-100 table m-0">${tableHtml}</table>`
                    }
                },
                {}
            ]
        })

        window.addEventListener('resize', () => this.alignProcessCoatingCells(table))
    }

    private alignProcessCoatingCells(table: JQuery<HTMLElement>) {
        const tpHeights = new Map<string, HTMLElement>();
        table.find('td[data-tp-height]').each((index, node) => {
            tpHeights.set(node.getAttribute('data-tp-height') || '', node);
        })
        table.find('td[data-tp]').each((index, node) => {
            const tpElement = tpHeights.get(node.getAttribute('data-tp') || '')
            if (!tpElement) return;

            if (node.style.height != '') node.style.height = '';
            if (tpElement.style.height != '') tpElement.style.height = '';

            const tpHeight = tpElement.getBoundingClientRect().height;
            const localHeight = node.getBoundingClientRect().height;

            if (tpHeight > localHeight) {
                node.style.height = `${tpHeight}px`;
            }
            if (tpHeight < localHeight) {
                tpElement.style.height = `${localHeight}px`;
            }
        })
    }
}

function buildProcessAndCoatings(row: DetailListItem): { code: string, name: string, coatings: string[] }[] {
    const processes = coalesceEmpty(row.processes);
    const coatings = coalesceEmpty(row.coatings);

    const table = new Map<number | null, { code: string, name: string, coatings: string[] }>()
    processes.forEach(p => {
        table.set(p.id, {code: p.code, name: p.name, coatings: []});
    })

    coatings.forEach(c => {
        const group = table.get(c.process) || {code: '', name: '', coatings: []}
        group.coatings.push(c.code);
        table.set(c.process, group);
    })

    const rows = Array.from(table.values());
    rows.sort((a, b) => a.code.localeCompare(b.code));
    rows.forEach(r => r.coatings.sort())
    return rows;
}