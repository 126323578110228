import {PlayerData, PlayerData_ContainerInfo, PlayerData_Position, PlayerData_Transport} from "./dto/com.rico.sb2.service.positions";
import {isUpdateContainerMessage, UpdateContainerMessage, UpdatePositionMessage, UpdateSchemeMessage, UpdateTransportMessage, visitUpdateSchemeMessage} from "./dto/com.rico.sb2.message";
import {ContainerDataAdapter, ContainerDataSupplier} from "./dashboard/ContainerDataSupplier";
import {ProcessControlService_Mode, TelemetryMessageProcessor_BathTelemetry} from "./dto/com.rico.sb2.service";
import {SchemaState} from "./SchemaState";
import {coalesce} from "./lib/coalesce";
import {ListenerList} from "./lib/ListenerList";
import {WebSocketClient} from "./WebSocketClient";
import just from "./lib/just";
import {PositionDataAdapter, PositionDataSupplier} from "./dashboard/PositionDataSupplier";
import {TransportDataAdapter, TransportDataSupplier} from "./dashboard/TransportDataSupplier";

export interface UpdateSchemeMessageConsumer {
    onSchemeUpdate?(update: UpdateSchemeMessage): void

    onSchemePositionTelemetry?(update: TelemetryMessageProcessor_BathTelemetry): void
}

class UpdateSchemeMessageListeners extends ListenerList<UpdateSchemeMessageConsumer> implements UpdateSchemeMessageConsumer {
    onSchemeUpdate(update: UpdateSchemeMessage) {
        this.visit(l => l.onSchemeUpdate?.(update))
    }

    onSchemePositionTelemetry(telemetry: TelemetryMessageProcessor_BathTelemetry) {
        this.visit(l => l.onSchemePositionTelemetry?.(telemetry))
    }
}


export interface PlayerDataListener {
    onContainerAdded?(m: PlayerData_ContainerInfo): void

    onContainerModify?(m: PlayerData_ContainerInfo): void

    onContainerRemoved?(m: PlayerData_ContainerInfo): void
}

class PlayerDataListeners extends ListenerList<PlayerDataListener> implements PlayerDataListener {
    onContainerAdded(m: PlayerData_ContainerInfo) {
        this.visit(c => c.onContainerAdded?.(m))
    }

    onContainerModify(m: PlayerData_ContainerInfo) {
        this.visit(c => c.onContainerModify?.(m))
    }

    onContainerRemoved(m: PlayerData_ContainerInfo) {
        this.visit(c => c.onContainerRemoved?.(m))
    }
}

export class PlayerDataAdapter {
    private readonly schemaState: SchemaState;

    private readonly containerData = new Map<number, PlayerData_ContainerInfo>()
    private readonly containerUpdate = new Map<number, UpdateContainerMessage>()
    private readonly positionData = new Map<number, PlayerData_Position>()
    private readonly positionUpdate = new Map<number, UpdatePositionMessage>()
    private readonly positionTelemetry = new Map<number, TelemetryMessageProcessor_BathTelemetry>()
    private readonly transportData = new Map<number, PlayerData_Transport>()
    private readonly transportUpdate = new Map<number, UpdateTransportMessage>()

    readonly playerDataListeners = new PlayerDataListeners()
    readonly updateListeners = new UpdateSchemeMessageListeners()

    constructor(schemaState: SchemaState) {
        this.schemaState = schemaState
    }

    reset(playerData: PlayerData, containerUpdates: UpdateSchemeMessage[]) {
        this.containerData.clear();
        this.containerUpdate.clear();

        coalesce(playerData.containers, []).forEach(c => this.containerData.set(c.id, c))
        coalesce(playerData.positions, []).forEach(p => this.positionData.set(p.id, p));
        coalesce(playerData.transports, []).forEach(t => this.transportData.set(t.id, t));

        coalesce(containerUpdates, [])
            .filter(m => isUpdateContainerMessage(m))
            .map(m => visitUpdateSchemeMessage(m, {
                containerMessage: m => this.containerUpdate.set(m.id, m),
                positionMessage: m => this.positionUpdate.set(m.id, m),
                transportMessage: m => this.transportUpdate.set(m.id, m)
            }))
    }

    subscribeToUpdates(ws: WebSocketClient) {
        ws.subscribe('/topic/scheme', m => this.onSchemeUpdate(m))
        ws.subscribe('/topic/containers/add', m => this.onContainerAdded(m))
        ws.subscribe('/topic/containers/modify', m => this.onContainerModify(m))
        ws.subscribe('/topic/containers/remove', m => this.onContainerRemoved(m))
        ws.subscribe('/topic/telemetry', m => this.onSchemePositionTelemetry(m))
    }

    get containers(): number[] {
        return Array.from(this.containerData.keys());
    }

    get positions(): PlayerData_Position[] {
        return Array.from(this.positionData.values());
    }

    get transports(): PlayerData_Transport[] {
        return Array.from(this.transportData.values());
    }

    getTransportUpdate(id: number | null): UpdateTransportMessage | null {
        if (id == null || !this.transportUpdate.has(id)) return null
        return this.transportUpdate.get(id) || null
    }

    getPositionUpdate(id: number | null): UpdatePositionMessage | null {
        if (id == null || !this.positionUpdate.has(id)) return null
        return this.positionUpdate.get(id) || null
    }

    getContainerData(id: number): ContainerDataAdapter {
        const self = this

        class ContainerDataSupplierImpl implements ContainerDataSupplier {
            get data() {
                return self.containerData.get(id) || ({} as PlayerData_ContainerInfo)
            }

            get serviceMode(): ProcessControlService_Mode | null {
                return self.schemaState.mode;
            }

            get update(): UpdateContainerMessage | null {
                return self.containerUpdate.get(id) || null
            }

            isLoadingPosition(pos: number | null): boolean {
                return pos !== null && self.schemaState.isLoadingPosition(pos);
            }

            getPositionTitle(pos: number | null): string {
                return self.schemaState.getPositionName(pos) || ''
            }

            getPositionShortTitle(pos: number | null): string {
                return self.schemaState.getPositionShortName(pos) || ''
            }

            getPositionTelemetry(pos: number | null): TelemetryMessageProcessor_BathTelemetry | null {
                if (pos == null) return null
                let latest = self.positionTelemetry.get(pos) || null
                if (latest == null) {
                    const data = self.positionData.get(pos) || null
                    if (data == null) return null
                    latest = {id: pos, temp: data.temp, current: data.current, voltage: data.voltage};
                }
                return latest

            }
        }

        return new ContainerDataAdapter(new ContainerDataSupplierImpl());
    }

    onContainerAdded(m: PlayerData_ContainerInfo) {
        console.info(just({_title: 'onContainerAdded', _date: new Date(), ...m}))

        this.containerData.set(m.id, m)
        this.playerDataListeners.onContainerAdded(m);
    }

    onContainerModify(m: PlayerData_ContainerInfo) {
        console.info(just({_title: 'onContainerModify', _date: new Date(), ...m}))

        this.containerData.set(m.id, m)
        this.playerDataListeners.onContainerModify(m);
    }

    onContainerRemoved(m: PlayerData_ContainerInfo) {
        console.info(just({_title: 'onContainerRemoved', _date: new Date(), ...m}))

        this.playerDataListeners.onContainerRemoved(m);
        this.containerData.delete(m.id)
        this.containerUpdate.delete(m.id)
    }

    onSchemeUpdate(m: UpdateSchemeMessage): void {
        visitUpdateSchemeMessage(m, {
            containerMessage: m => this.onSchemeContainerUpdate(m),
            transportMessage: m => this.onSchemeTransportUpdate(m),
            positionMessage: m => this.onSchemePositionUpdate(m)
        })
    }

    onSchemeContainerUpdate(m: UpdateContainerMessage): void {
        console.info(just({_title: 'onSchemeContainerUpdate', _date: new Date(), ...m}))

        this.containerUpdate.set(m.id, m)
        this.updateListeners.onSchemeUpdate(m)
    }

    onSchemePositionUpdate(m: UpdatePositionMessage): void {
        // console.info(just({_title: 'onSchemePositionUpdate', _date: new Date(), ...m}))

        // некоторые поля мы обновляем и у initial data, потому что оно уже сохранено в базе.
        if (m.locked != null) {
            this.positionData.get(m.id)!.locked = m.locked;
        }

        this.positionUpdate.set(m.id, m)
        this.updateListeners.onSchemeUpdate(m)
    }

    onSchemePositionTelemetry(m: TelemetryMessageProcessor_BathTelemetry): void {
        this.positionTelemetry.set(m.id, m)
        this.updateListeners.onSchemePositionTelemetry(m)
    }

    onSchemeTransportUpdate(m: UpdateTransportMessage): void {
        console.info(just({_title: 'onSchemeTransportUpdate', _date: new Date(), ...m}))

        this.transportUpdate.set(m.id, m)
        this.updateListeners.onSchemeUpdate(m)
    }

    getPositionData(id: number): PositionDataAdapter {
        const self = this

        class PositionDataAdapterImpl implements PositionDataSupplier {
            get data() {
                return self.positionData.get(id) || ({} as PlayerData_Position)
            }

            get serviceMode(): ProcessControlService_Mode | null {
                return self.schemaState.mode;
            }

            get update(): UpdatePositionMessage | null {
                return self.positionUpdate.get(id) || null
            }

            get telemetry(): TelemetryMessageProcessor_BathTelemetry | null {
                let latest = self.positionTelemetry.get(id) || null
                if (latest == null) {
                    const data = this.data
                    latest = {id, temp: data.temp, current: data.current, voltage: data.voltage};
                }
                return latest
            }

            getPositionTitle(pos: number | null): string {
                return self.schemaState.getPositionName(pos) || ''
            }

            get loadingPosition(): boolean {
                return self.schemaState.isLoadingPosition(id);
            }

            get unloadingPosition(): boolean {
                return self.schemaState.isUnloadingPosition(id);
            }
        }

        return new PositionDataAdapter(new PositionDataAdapterImpl());
    }

    getTransportData(id: number): TransportDataAdapter {
        const self = this

        class TransportDataAdapterImpl implements TransportDataSupplier {
            get data() {
                return self.transportData.get(id) || ({} as PlayerData_Transport)
            }

            get serviceMode(): ProcessControlService_Mode | null {
                return self.schemaState.mode;
            }

            get update(): UpdateTransportMessage | null {
                return self.transportUpdate.get(id) || null
            }

            getPositionTitle(pos: number | null): string {
                return self.schemaState.getPositionName(pos) || ''
            }

            findContainerOnTransport(): number | null {
                const container = Array
                    .from(self.containerUpdate.values())
                    .find(cu => cu.transport == id);
                if (container != null) {
                    return container.id;
                }
                return null;
            }

            findContainerOnPosition(position: number): number | null {
                const containerPositions = new Map<number, number | null>();
                Array.from(self.containerData.values()).forEach(cd => containerPositions.set(cd.id, cd.position));
                Array.from(self.containerUpdate.values()).forEach(cd => containerPositions.set(cd.id, cd.position));

                const containerPosition = Array
                    .from(containerPositions.entries())
                    .find(cu => cu[1] == position);
                return containerPosition ? containerPosition[0] : null;
            }
        }

        return new TransportDataAdapter(new TransportDataAdapterImpl());
    }

    isLoadingPosition(id: number): boolean {
        return this.schemaState.isLoadingPosition(id);
    }

    isUnloadingPosition(id: number): boolean {
        return this.schemaState.isUnloadingPosition(id);
    }
}