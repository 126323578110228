import {SendContainerToLoading} from "./modals/SendContainerToLoading";
import {AppConfig} from "./AppConfig";
import {SendContainerToWork} from "./modals/SendContainerToWork";
import {CancelContainer} from "./modals/CancelContainer";
import {reloadPage} from "./lib/domFunctions";
import {SendContainerToPosition} from "./modals/SendContainerToPosition";
import {FinishContainer} from "./modals/FinishContainer";
import {ContainerState} from "./dto/com.rico.sb2.entity.detail";
import {DisbandContainer} from "./modals/DisbandContainer";

export class ContainerViewPage {
    private readonly id: number
    private readonly state: ContainerState

    constructor({id, state}: { id: number, state: ContainerState }) {
        this.id = id
        this.state = state
    }

    sendToLoading() {
        new SendContainerToLoading(this.id).showModal(reloadPage);
    }


    sendToWork() {
        new SendContainerToWork(this.id).showModal(reloadPage);
    }


    move() {
        new SendContainerToPosition(this.id, null).showModal(reloadPage);
    }

    finish() {
        if (this.id == null) return
        new FinishContainer(this.id).showModal(reloadPage);
    }

    disband() {
        if (this.id == null) return
        new DisbandContainer(this.id).showModal(reloadPage);
    }

    cancel() {
        const completer = () => document.location.href = `${AppConfig.CP}/queue`
        new CancelContainer(this.id, this.state).showModal(completer);
    }
}