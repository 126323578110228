import {fetchJsonForApiResponse, toastFetchError} from "./lib/fetch";
import {escapeHTML} from "./lib/escapeHTML";
import {byId, createHtmlElement, reloadPage, toggleById, toggleHidden} from "./lib/domFunctions";
import {AppConfig} from "./AppConfig";
import {DetailSearch} from "./modals/DetailSearch";
import {SendContainerToLoading} from "./modals/SendContainerToLoading";
import {SendContainerToWork} from "./modals/SendContainerToWork";
import {CancelContainer} from "./modals/CancelContainer";
import {SendContainerToPosition} from "./modals/SendContainerToPosition";
import {FinishContainer} from "./modals/FinishContainer";
import {ContainerState, ContainerType} from "./dto/com.rico.sb2.entity.detail";
import {CoatingListItem, DetailListItem} from "./dto/com.rico.sb2.service.details";
import {ProcessListItem} from "./dto/com.rico.sb2.service.process";
import {ProcessInputField} from "./modals/ProcessInputField";
import {floatTrim5Text, numbersToCommaString, numberStringOrEmpty, toEmptyString, zeroWhenNaN} from "./lib/coalesce";
import {ContainerForm_ActionForm} from "./dto/com.rico.sb2.service.queue";
import {DisbandContainer} from "./modals/DisbandContainer";

const H = escapeHTML;

export class ContainerEditPage {
    private readonly id: number | null
    private readonly state: ContainerState | null
    private readonly programEditable: boolean
    private readonly currentDensities: number[]

    private readonly form: HTMLFormElement
    private readonly processInput: ProcessInputField

    constructor(params: { id: number | null, state: ContainerState | null, currentDensities?: number[], programEditable: boolean }) {
        const self = this

        this.id = params.id
        this.state = params.state
        this.programEditable = params.programEditable
        this.currentDensities = params.currentDensities || []
        this.form = document.getElementById('containerForm') as HTMLFormElement

        this.processInput = new ProcessInputField({
            valueField: this.form.querySelector('input[name="process"]')!,
            textField: this.form.querySelector('input[name="processName"]')!,
            change: (data) => this.useProcess(data),
            request: (search) => fetchJsonForApiResponse(`/queue/op/selectProcesses?search=${encodeURIComponent(search)}&details=${self.getSelectedDetailsCommaString()}&offset=0&limit=10`)
        })

        document.getElementById('detailsTable')?.addEventListener('input', () => this.countDetailSummary())
        this.hideNonDynamicCheckbox.addEventListener('change', () => this.hideNonDynamicUpdate())
        this.selfAreaInput.addEventListener('input', () => this.analyzeCurrentInSteps(true))
        this.detailAreaInput.addEventListener('input', () => this.analyzeCurrentInSteps(true))
        this.processActionsTable.addEventListener('change', e => {
            const target = e.target as HTMLSelectElement
            if (target.tagName == 'SELECT' && target.name.endsWith('.currentDensity')) {
                this.analyzeCurrentInStepsRow(target.closest('tr')!, true);
            }
            if (target.tagName == 'INPUT' && target.name.endsWith('.currentValue')) {
                this.analyzeCurrentInStepsRow(target.closest('tr')!, false);
            }
        });
        this.processActionsTable.addEventListener('input', e => {
            const target = e.target as HTMLSelectElement
            if (target.tagName == 'INPUT' && target.name.endsWith('.currentValue')) {
                this.analyzeCurrentInStepsRow(target.closest('tr')!, false);
            }
        });
        this.analyzeCurrentInSteps(false);
    }

    private get processActionsTable(): HTMLTableElement {
        return byId<HTMLTableElement>('containerProcessActions');
    }

    private get selfAreaInput(): HTMLInputElement {
        return this.form.querySelector('input[name="selfArea"]')!!
    }

    private get hideNonDynamicCheckbox(): HTMLInputElement {
        return this.form.querySelector('input#hideNonDynamic')!!
    }

    private get detailAreaInput(): HTMLInputElement {
        return this.form.querySelector('input[name="detailArea"]')!!
    }

    private get detailWeightInput(): HTMLInputElement {
        return this.form.querySelector('input[name="detailWeight"]')!!
    }

    private hideNonDynamicUpdate() {
        this.processActionsTable.classList.toggle('hide-group1-hide', this.hideNonDynamicCheckbox.checked);
    }

    private useProcess(process: ProcessListItem | null) {
        return this.populateProcessActions(process)
            .then(() => this.populateProcessCoatings())
            .then(() => Promise.resolve(true))
            .catch(() => {
                alert('Произошла ошибка при запросе информации о процессе! Перезагрузите страницу.')
                return Promise.resolve(false)
            })
    }

    private populateProcessActions(process: ProcessListItem | null): Promise<any> {
        toggleById('containerProcessSetup', process != null);
        if (process == null) {
            this.resetStepTable([]);
            return Promise.resolve();
        }

        if (process && process.containerType && process.containerType != ContainerType.NOT_DEFINED) {
            byId<HTMLSelectElement>('type').value = process.containerType;
        }

        // загружаем и рисуем таблицу конфигурирования шагов
        return fetchJsonForApiResponse(`${AppConfig.CP}/queue/op/queryProcessActions?process=${process.id}`)
            .then(forms => this.resetStepTable(forms))
    }

    private populateProcessCoatings(): Promise<any> {
        const select = this.form.querySelector<HTMLSelectElement>('select[name="coating"]')!
        const selectValue = parseInt(select.value);
        select.innerHTML = ``;

        const process = this.processInput.value
        toggleById('containerProcessCoatings', process != null);
        if (process == null) {
            return Promise.resolve(true);
        }

        const populateSelect = (coatings: CoatingListItem[]) => {
            select.innerHTML = coatings
                .sort((a, b) => toEmptyString(a.code).localeCompare(toEmptyString(b.code)))
                .map(c => `<option value="${c.id}">${c.code} ${c.thickness}</option>`)
                .join("")

            if (coatings.some(c => c.id === selectValue)) {
                select.value = selectValue.toString();
            }
        }

        return fetchJsonForApiResponse(`${AppConfig.CP}/queue/op/selectCoatings?process=${process}&details=${this.getSelectedDetailsCommaString()}`)
            .then(coatings => populateSelect(coatings))
            .catch(error => {
                toastFetchError(error)
                populateSelect([])
            })
    }

    private resetStepTable(forms: ContainerForm_ActionForm[]) {
        const currentActionsBody = this.processActionsTable.querySelector('tbody') as HTMLTableSectionElement
        let currentActionsHasCurrentInputs = false;

        const allowedDensities = this.currentDensities

        function renderStepRow(form: ContainerForm_ActionForm, index: number) {
            let densityInput = ""
            let currentInput = ''
            if (form.needCurrentDensity) {
                currentActionsHasCurrentInputs = true

                let densityDefault: number | null = null;
                if (form.currentDensityMin != null && form.currentDensityMax != null && form.currentDensityMin == form.currentDensityMax) {
                    densityDefault = form.currentDensityMin;
                }

                let densityDefaultList: number[] | string = allowedDensities
                    .filter(n => n != null && (form.currentDensityMin == null || form.currentDensityMin <= n) && (form.currentDensityMax == null || n <= form.currentDensityMax))
                    .map(n => `<option value="${n}" ${densityDefault == n ? 'selected' : ''}>${n}</option>`)
                    .join("")
                densityInput = `<select class="form-select w-auto d-inline-block" name="actions[${index}].currentDensity">${densityDefaultList}</select>`
                currentInput = `<input type="number" step="any" class="form-control w-7em d-inline-block text-center" name="actions[${index}].currentValue" required>`
            }

            let maxCurrent = !form.maxCurrent ? '' : `<span data-bind="maxCurrent">${form.maxCurrent}</span>`
            const duration = !form.dynamic && (form.durationMin || form.durationMax) ? `${numberStringOrEmpty(form.durationMin)}-${numberStringOrEmpty(form.durationMax)}` : ''

            return `
<tr class="align-baseline ${!form.needCurrentDensity ? ' hide-group1 ' : ''}">
    <td class="py-3">${index + 1}</td>
    <td>
        <input type="hidden" name="actions[${index}].id" value="${form.id}">
        <span>${H(form.positionType)}</span>
    </td>
    <td class="text-center">${H(form.positionCoating)}</td>
    <td class="text-center">${duration}</td>
    <td class="text-center table-hide1-hidden">${densityInput}</td>
    <td class="text-center table-hide1-hidden">${currentInput}</td>
    <td class="text-end table-hide1-hidden">${maxCurrent}</td>
</tr>`
        }

        currentActionsBody.before(createHtmlElement('tbody', {}, forms.map(renderStepRow).join("")))
        currentActionsBody.remove()

        this.processActionsTable.classList.toggle('table-hide1', !currentActionsHasCurrentInputs);
        this.processActionsTable.classList.toggle('hidden', forms.length == 0)
        this.analyzeCurrentInSteps(true)

        if (!currentActionsHasCurrentInputs && this.hideNonDynamicCheckbox.checked) {
            this.hideNonDynamicCheckbox.checked = false;
        }
        this.hideNonDynamicUpdate();
    }

    addDetail() {
        const detailsTable = document.getElementById('detailsTable')!!
        const detailsTableBody = detailsTable.querySelector('tbody')!!

        function selectedDetails() {
            return Array.from(detailsTableBody.querySelectorAll('input'))
                .filter(input => /^details\[\d+].id$/.test(input.getAttribute('name') || ''))
                .map(input => parseInt(input.value))
        }

        function getCoatingNumberString() {
            const input = document.getElementById('coating') as HTMLInputElement
            const inputValue = input && !isNaN(parseInt(input.value)) ? parseInt(input.value) : null
            return numberStringOrEmpty(inputValue);
        }

        new DetailSearch({
            selectPredicate: item => selectedDetails().indexOf(item.id) < 0,
            selectHandler: (item, dialog) => {
                this.addDetailItem(item)
                dialog.search()
                return true
            },
            request: (searchText) => {
                const selectedCoating = getCoatingNumberString();
                return fetchJsonForApiResponse(`/queue/op/searchDetails?searchText=${encodeURIComponent(searchText)}&coating=${selectedCoating}&details=${numbersToCommaString(selectedDetails())}`);
            }
        });
    }

    private addDetailItem(item: DetailListItem) {
        const detailsTable = document.getElementById('detailsTable')!!
        const detailsTableBody = detailsTable.querySelector('tbody')!!

        const tr = createHtmlElement('tr', {'class': 'align-baseline', 'data-id': item.id.toString()}, `
        <td>
            <input type="hidden" name="details[0].id" value="${item.id}">
            <div><span>${H(item.code)}</span><span> / </span><span>${H(item.name)}</span></div>
        </td>
        <td class="text-center"><span data-bind="area">${item.area == null ? '-' : item.area}</span></td>
        <td class="hidden"><span data-bind="weight">${item.weight == null ? '-' : item.weight}</span></td>
        <td class="text-center"><input data-bind="amount" type="number" class="form-control form-control-sm text-center w-7em mx-auto d-inline-block" name="details[0].amount" value="1" min="1" step="1" required></td>
        <td class="text-end d-print-none"><button type="button" class="btn btn-sm btn-secondary text-danger" onclick="controller.removeDetail(${item.id})">x</button></td>
`)

        detailsTableBody.append(tr)
        this.enumerateDetailTable();
        this.countDetailSummary();
    }

    private getSelectedDetailsCommaString(): string {
        return this.getSelectedDetails().join(",");
    }

    private getSelectedDetails(): number[] {
        const detailsTable = document.getElementById('detailsTable')!!
        const rows = detailsTable
            ?.querySelector('tbody')
            ?.querySelectorAll(`tr[data-id]`)
        if (!rows) return []

        return Array.from(rows)
            .map(tr => tr.getAttribute('data-id'))
            .map(id => parseInt(id || ''))
            .filter(id => Number.isFinite(id))
    }

    removeDetail(id: number) {
        const detailsTable = document.getElementById('detailsTable')!!
        const row = detailsTable
            ?.querySelector('tbody')
            ?.querySelector(`tr[data-id="${id}"]`)
        if (!row) return

        row.remove()
        this.enumerateDetailTable();
        this.countDetailSummary();
        this.populateProcessCoatings();
    }

    private countDetailSummary() {
        function inputToFloat(input: HTMLInputElement | null) {
            return input != null && input.textContent != null && /^[\d.]+$/.test(input.textContent) ? parseFloat(input.textContent.trim()) : null
        }

        const detailsTable = document.getElementById('detailsTable')!!
        const detailsProps = Array
            .from(detailsTable.querySelectorAll<HTMLTableRowElement>('tr[data-id]'))
            .map(tr => {
                const amountInput = tr.querySelector<HTMLInputElement>('input[data-bind="amount"]')
                const amount = amountInput == null ? 0 : parseInt(amountInput.value);

                const area = inputToFloat(tr.querySelector('[data-bind="area"]'))
                const weight = inputToFloat(tr.querySelector('[data-bind="weight"]'))

                return {
                    area: area == null || !Number.isFinite(area) ? null : area * amount,
                    weight: weight == null || !Number.isFinite(weight) ? 0 : weight * amount,
                }
            })
            .filter(p => p.area != null || p.weight != null)


        function sumOfFloats<T>(array: T[], mapper: ((item: T) => number | null)): string {
            let slice = array.map(mapper).filter(p => p != null) as number[]
            if (slice.length > 0) {
                const sum = slice.sort((a, b) => a - b)
                    .reduce((prev, next) => prev + next)
                return floatTrim5Text(sum);
            }
            return '';
        }

        this.detailAreaInput.value = sumOfFloats(detailsProps, p => p.area)
        this.detailWeightInput.value = sumOfFloats(detailsProps, p => p.weight)

        this.analyzeCurrentInSteps(true)
    }

    private analyzeCurrentInStepsRow(tr: HTMLTableRowElement, setCurrentValue: boolean) {
        const currentValueInput = findByName(/\.currentValue$/, tr.querySelectorAll('input'))
        if (!currentValueInput) return

        function findByName(nameTest: RegExp, elements: NodeListOf<HTMLSelectElement | HTMLInputElement>) {
            const found = Array.from(elements).filter(t => nameTest.test(t.name))
            return found.length === 1 ? found[0] : null
        }

        const selfArea = zeroWhenNaN(parseFloat(this.selfAreaInput.value))
        const detailArea = zeroWhenNaN(parseFloat(this.detailAreaInput.value))
        const totalArea = selfArea + detailArea

        const currentDensityInput = findByName(/\.currentDensity$/, tr.querySelectorAll('select'))
        if (currentDensityInput && setCurrentValue) {
            const currentDensity = zeroWhenNaN(parseFloat(currentDensityInput.value))
            currentValueInput.value = Math.ceil(currentDensity * totalArea).toString();
        }


        const maxCurrent = tr.querySelector('span[data-bind="maxCurrent"]') as HTMLElement
        const maxCurrentValue = maxCurrent ? parseFloat(maxCurrent.innerText) : Number.NaN
        const currentValue = parseFloat(currentValueInput.value)
        currentValueInput.classList.toggle('text-danger', Number.isFinite(currentValue) && Number.isFinite(maxCurrentValue) && currentValue > maxCurrentValue)
    }

    private analyzeCurrentInSteps(setCurrentValue: boolean) {
        const body = this.processActionsTable.querySelector('tbody') as HTMLTableSectionElement
        if (!body) return

        body.querySelectorAll('tr').forEach(tr => {
            this.analyzeCurrentInStepsRow(tr, setCurrentValue)
        })
    }

    private enumerateDetailTable() {
        const detailsTable = document.getElementById('detailsTable')!!
        const detailsTableBody = detailsTable.querySelector('tbody')!!

        let count = 0
        detailsTableBody.querySelectorAll('tr').forEach(tr => {
            Array
                .from(tr.querySelectorAll('input'))
                .forEach(input => input.name = input.name.replace(/^details\[\d+]./, `details[${count}].`))
            count += 1
        })

        toggleHidden(detailsTable, count == 0)
        toggleHidden(document.getElementById('detailsEmptyNotice')!!, count > 0)
    }

    sendToLoading() {
        if (this.id == null) return
        new SendContainerToLoading(this.id).showModal(reloadPage);
    }

    sendToWork() {
        if (this.id == null) return
        new SendContainerToWork(this.id).showModal(reloadPage);
    }

    move() {
        if (this.id == null) return
        new SendContainerToPosition(this.id, null).showModal(reloadPage);
    }

    finish() {
        if (this.id == null) return
        new FinishContainer(this.id).showModal(reloadPage);
    }

    disband() {
        if (this.id == null) return
        new DisbandContainer(this.id).showModal(reloadPage);
    }

    cancel() {
        if (this.id == null || this.state == null) return
        const completer = () => document.location.href = `${AppConfig.CP}/queue`
        new CancelContainer(this.id, this.state).showModal(completer);
    }
}

