import {SchemaPlayer} from "./player/SchemaPlayer";
import {WebSocketClient} from "./WebSocketClient";
import {createAnchorAndClick, hasHidden, toggleHidden} from "./lib/domFunctions";
import {apiGetForAjaxResponse, apiPostForAjaxResponse} from "./lib/SecuredAjax";
import {PositionPopover} from "./dashboard/PositionPopover";
import {TransportPopover} from "./dashboard/TransportPopover";
import {fetchJsonForApiResponse, toastFetchError} from "./lib/fetch";
import {SchemaState} from "./SchemaState";
import {TransportType} from "./dto/com.rico.sb2.entity.device";
import {ProcessControlService_Mode, ProcessControlService_Status} from "./dto/com.rico.sb2.service";
import {AppConfig} from "./AppConfig";
import {PlayerStateData} from "./dto/com.rico.sb2.controllers.dto";
import {buttonProgress} from "./lib/buttonProgress";
import {AliveList} from "./dashboard/AliveList";
import {FinishedList} from "./dashboard/FinishedList";
import {PlayerDataAdapter} from "./PlayerDataAdapter";
import {ContainerClickMenu} from "./dashboard/ContainerClickMenu";
import {DashboardForm} from "./DashboardForm";
import {ContainerTypeInfo} from "./dto/com.rico.sb2.service.queue";
import {AppBus, AppEvents} from "./AppBus";
import {SinglePopover} from "./lib/bootstrapPopover";
import {UserPermissions} from "./UserPermissions";

interface DashboardParams {
	mode?: ProcessControlService_Mode,
	modeSwitches?: any,
	positionNames: { id: number, type: number, line: number, label: string, shortTitle: string }[],
	ptLoading: number | null,
	ptUnloading: number | null,
	transportNames: { id: number, type: TransportType | null, number: any }[],
	allowedDensities: number[],
	containerTypes: ContainerTypeInfo[],
	canControl: boolean
}

export class Dashboard {
	private readonly ws: WebSocketClient
	private readonly schemaState: SchemaState
	private readonly playerData: PlayerDataAdapter
	private readonly allowedDensities: number[]

	private readonly display: HTMLElement
	private readonly canControl: boolean

	private player: SchemaPlayer | null = null;

	private readonly modeSwitches: any
	private readonly modeSwitchesButtons: Map<ProcessControlService_Mode, HTMLButtonElement> = new Map()

	private readonly aliveList: AliveList
	private readonly finishedList: FinishedList
	private finishedShown = false

	private dashboardForm: DashboardForm | null = null
	private readonly smallScreen: boolean

	private readonly permissions: UserPermissions;

	constructor({mode, modeSwitches, canControl, positionNames, ptLoading, ptUnloading, transportNames, allowedDensities}: DashboardParams) {
		this.display = document.getElementById('schemaDisplay')!!
		this.canControl = canControl
		this.allowedDensities = allowedDensities
		this.permissions = AppConfig.userPermissions || {} as UserPermissions;

		this.schemaState = new SchemaState(mode || null)
		this.schemaState.setPositionNames(positionNames)
		this.schemaState.setTransportNames(transportNames)
		this.schemaState.setPtLoading(ptLoading);
		this.schemaState.setPtUnloading(ptUnloading);
		this.schemaState.modeListeners.add({
			onServiceModeChanged: () => this.updateStatusUI()
		})

		this.playerData = new PlayerDataAdapter(this.schemaState)

		this.aliveList = new AliveList(this.schemaState, this.playerData)
		this.finishedList = new FinishedList(this.schemaState, this.playerData)

		this.modeSwitches = modeSwitches || {}
		document.querySelectorAll<HTMLButtonElement>('button[data-bind="switchMode"][data-arg]').forEach(button => {
			const mode = button.getAttribute('data-arg') as ProcessControlService_Mode
			this.modeSwitchesButtons.set(mode, button)
			button.addEventListener('click', () => this.switchMode(mode as ProcessControlService_Mode))
		})
		this.updateStatusUI();

		this.ws = WebSocketClient.instance();
		this.playerData.subscribeToUpdates(this.ws)
		this.schemaState.subscribeToUpdates(this.ws)
		this.ws.listeners.add({
			onConnected: this.onWebSocketConnected.bind(this)
		})

		if (this.ws.connected) {
			this.onWebSocketConnected();
		}

		this.smallScreen =
			(AppConfig.hideDashboardForm.minWindowWidth != null && window.innerWidth < AppConfig.hideDashboardForm.minWindowWidth) ||
			(AppConfig.hideDashboardForm.minWindowHeight != null && window.innerHeight < AppConfig.hideDashboardForm.minWindowHeight);
		if (this.smallScreen) {
			console.info(`small screen (minw=${AppConfig.hideDashboardForm.minWindowWidth}, minh=${AppConfig.hideDashboardForm.minWindowHeight}) > (winw=${window.innerWidth}, winh=${window.innerHeight})`);
		}
		if (!this.smallScreen && AppConfig.logged()) {
			this.showCreateContainerForm();
		}

		const showCreateContainerFormHidden = AppConfig.roles.length == 0;
		document.querySelectorAll<HTMLButtonElement>('button[data-bind="showCreateContainerForm"]').forEach(node => toggleHidden(node, showCreateContainerFormHidden));

		AppBus.addEventListener(AppEvents.VIEW_CONTAINER, (id: number, inplace?: boolean) => {
			SinglePopover.hideCurrent()
			this.viewContainer(id, inplace)
		})
		AppBus.addEventListener(AppEvents.EDIT_CONTAINER, (id: number) => {
			SinglePopover.hideCurrent()
			createAnchorAndClick(`${AppConfig.CP}/queue/${id}/edit`, true)
		})
		AppBus.addEventListener(AppEvents.PRINT_CONTAINER, (id: number) => {
			SinglePopover.hideCurrent()
			const printWindow = window.open(`${AppConfig.CP}/queue/${id}`, '_blank')!;
			printWindow.addEventListener('load', function () {
				if (Boolean((printWindow as any).chrome)) {
					printWindow.print();
					setTimeout(function () {
						printWindow.close();
					}, 250);
				} else {
					printWindow.print();
					printWindow.close();
				}
			}, true);
		})
	}

	private onWebSocketConnected() {
		console.info('refresh status')
		apiGetForAjaxResponse(`${AppConfig.CP}/op/status`).then((m: ProcessControlService_Status) => this.schemaState.mode = m.mode)

		console.info('refresh player');
		apiGetForAjaxResponse(`${AppConfig.CP}/op/playerStateData`).then((m: PlayerStateData) => {
			this.displaySvg(m)
			this.aliveList.clear();

			if (this.player) {
				this.aliveList.reset()
				this.showListAlive();
			}
		})
	}

	private showListAlive() {
		this.finishedShown = false;
		toggleHidden(this.finishedList.element, !this.finishedShown);
		toggleHidden(this.aliveList.element, this.finishedShown);
	}

	private showListFinished() {
		this.finishedShown = true;
		toggleHidden(this.finishedList.element, !this.finishedShown);
		toggleHidden(this.aliveList.element, this.finishedShown);
	}

	private updateStatusUI() {
		const nextStates = this.schemaState.mode == null ? [] : this.modeSwitches[this.schemaState.mode] || []
		const canTo = (state: ProcessControlService_Mode) => nextStates.indexOf(state) >= 0
		for (const [mode, button] of this.modeSwitchesButtons) {
			toggleHidden(button, !this.canControl || !canTo(mode));
		}
	}

	private switchMode(mode: ProcessControlService_Mode) {
		const progress = buttonProgress(this.modeSwitchesButtons.get(mode));
		apiPostForAjaxResponse(`/switchMode?mode=${mode}`)
			.catch(reason => toastFetchError(reason))
			.then(() => progress.stop());
	}

	private displaySvg(init: PlayerStateData) {
		this.player?.clear()
		this.player = null

		const playerData = init.playerData
		this.playerData.reset(playerData, init.initialMessages || [])

		if (!init.playerSvg) return;

		this.player = new SchemaPlayer(this.display, init.playerSvg, playerData.containerSvg, playerData.containerCss, this.playerData, init.initialMessages || [], {
			getContainerPopover: (player: SchemaPlayer, id: number) => new ContainerClickMenu(this.playerData, id),
			getPositionPopover: (player: SchemaPlayer, id: number) => new PositionPopover(this.playerData, id, this.permissions),
			getTransportPopover: (player: SchemaPlayer, id: number) => new TransportPopover(this.playerData, id),
		});

		this.showHideDashboardWidgets();
	}

	private showHideDashboardWidgets() {
		const dashboardWidgets = document.getElementById('dashboardWidgets');
		toggleHidden(dashboardWidgets, false);

		const dashboardWidgetsHidden = this.smallScreen;
		toggleHidden(dashboardWidgets, dashboardWidgetsHidden || false);
	}

	showCreateContainerForm(always: boolean = false) {
		const dashboardWidgets = document.getElementById('dashboardWidgets');
		if (hasHidden(dashboardWidgets) && always) {
			createAnchorAndClick(`${AppConfig.CP}/queue/create`, true);
			return;
		}

		this.displayContainerForm(this.buildContainerForm());
	}

	private buildContainerForm() {
		return new DashboardForm({allowedDensities: this.allowedDensities});
	}

	private displayContainerForm(dashboardForm: DashboardForm | null) {
		if (this.dashboardForm) {
			this.dashboardForm.element.remove()
			this.dashboardForm = null
		}

		this.dashboardForm = dashboardForm;

		if (this.dashboardForm) {
			this.dashboardForm.postSubmit = () => {
				this.showCreateContainerForm();
			};
			document.getElementById('containerForm')?.append(this.dashboardForm.element)
		}
	}

	private viewContainer(id: number, inplace: boolean = false) {
		if (inplace && this.smallScreen) inplace = false;

		if (!inplace) {
			createAnchorAndClick(`${AppConfig.CP}/queue/${id}`, true)
			return;
		}

		this.displayContainerForm(null);

		fetchJsonForApiResponse(`${AppConfig.CP}/queue/${id}/edit`)
			.then(form => {
				if (!form.editable) {
					createAnchorAndClick(`${AppConfig.CP}/queue/${id}`, true)
					return
				}
				this
					.buildContainerForm()
					.initWithForm(id, form)
					.then(form => this.displayContainerForm(form));
			})
			.catch(toastFetchError)
	}
}
