import {showToastError} from "./boostrapToast";
import {Messages} from "../messages/Messages";

const messages = new Messages();

export function fetchJsonForApiResponse(input: RequestInfo, init?: RequestInit): Promise<any> {
    return fetchJson(input, init)
        .then(res => isAjaxResponseOk(res) ? res.value : Promise.reject(res.message))
}

export function fetchJson(input: RequestInfo, init?: RequestInit): Promise<any> {
    if (init == null || typeof init === 'undefined') {
        init = {};
    }
    init.headers = Object.assign({}, init.headers, {
        'Accept': 'application/json'
    })
    return fetch(input, init)
        .then(response => {
            if (response.ok)
                return response.json()
            return Promise.reject(response.status)
        })
}

export function fetchHtml(input: RequestInfo, init?: RequestInit): Promise<any> {
    if (init == null || typeof init === 'undefined') {
        init = {};
    }
    init.headers = Object.assign({}, init.headers, {
        'Accept': 'text/html'
    })
    return fetch(input, init)
        .then(response => {
            if (response.ok)
                return response.text()
            return Promise.reject(response.status)
        })
}

export function toastFetchError(reason: any, otherMessage?: string) {
    if (403 === reason) {
        showToastError(messages.get(`restApi.403.toast.body`), {header: messages.get(`restApi.${reason}.toast.title`), html: true})
    } else if ([404, 413, 500].indexOf(reason) >= 0) {
        showToastError(messages.get(`restApi.${reason}.toast.body`), {header: messages.get(`restApi.${reason}.toast.title`)})
    } else if (typeof reason === 'string') {
        showToastError(reason)
    } else if ('message' in reason) {
        showToastError(reason['message']);
    } else {
        showToastError(otherMessage == undefined ? messages.get('restApi.genericError') : otherMessage)
    }
}

export function isAjaxResponseOk(res: any) {
    return res && res.status === 'ok';
}

export function isAjaxResponseErrors(res: any) {
    return res && res.status === 'error' && res.errors;
}